import React from "react";
import "./LandingPage6VideoBlock.css";

export default function LandingPage6VideoBlock() {
  return (
    <>
      <div className="lpage_logowrapper">
        <img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_logo.png" />
      </div>
      <div className="landpage_videowrapper">
        <div className="landpage_video_subwrapper">
          <iframe
            width=""
            height=""
            src="https://player.vimeo.com/video/718787736?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0&h=cd4c0d0ed4#t="
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      {/* <div className="video_img">
     <img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/video_img.webp" />
     </div> */}
      <div className="land_arrow_wrapper">
        <h2>Please fill out the form and schedule to talk with our experts!</h2>
      </div>
    </>
  );
}
