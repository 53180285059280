import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import store from "../../../store";
import { getInformationListData } from "./InformationReducer";

const Informationtemplate = () => {
  let infoLinksData = useSelector((state) =>
    state.informationReducer?.informationlistdata &&
    state.informationReducer?.informationlistdata.length > 0
      ? state.informationReducer.informationlistdata
      : []
  );

  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        // "added_for": addedForId
      },
      count: false,
      source: "",
    };

    store.dispatch(getInformationListData(reqbody));
  }, []);

  return (
    <>
      <div>
        <h2>{infoLinksData[0]?.title}</h2>
        <span>{infoLinksData[0]?.description}</span>
        <div>
          {infoLinksData[0]?.file_link.map((data) => {
            console.log("datanew", data);
            return (
              <>
                <div>
                  {data?.type == "video-youtube" && (
                    <div className="player-wrapper">
                      <h2>{data?.title}</h2>
                      <span>{data?.description}</span>
                      <ReactPlayer
                        className="react-player"
                        url={`https://www.youtube.com/watch?v=${data?.youtube.id}`}
                        width="100%"
                        height="100%"
                        controls={true}
                        light={data?.youtube?.thumbnail_link}

                        // playing={play}
                      />
                    </div>
                  )}
                </div>

                <div>
                  {data?.type == "video-vimeo" && (
                    <div>
                      {" "}
                      <h2>{data?.title}</h2>
                      <span>{data?.description}</span>

                      <ReactPlayer
                        className="react-player"
                        url={data?.vimeo}
                        width="100%"
                        height="100%"
                        controls={true}
                        light={data?.youtube?.thumbnail_link}

                        // playing={play}
                      />
                    </div>
                  )}
                </div>

                <div>
                  {data?.type == "video-link" && (
                    <div>
                      {" "}
                      <h2>{data?.title}</h2>
                      <span>{data?.description}</span>

                      <ReactPlayer
                        className="react-player"
                        url={data?.video}
                        width="100%"
                        height="100%"
                        controls={true}
                        // light={data?.video}

                        // playing={play}
                      />
                    </div>
                  )}
                </div>

                <div>
                  {data?.type == "video-upload" && (
                    <div>
                      {" "}
                      <h2>{data?.title}</h2>
                      <span>{data?.description}</span>
                    </div>
                  )}
                </div>

                <div>
                  {data?.type == "file" && (
                    <div>
                      {" "}
                      <h2>{data?.title}</h2>
                      <span>{data?.description}</span>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Informationtemplate;
