import React from 'react'
import "../../LandingPage6.css";

export default function LandingPageBlockForVideo() {
  return (
    <>
      {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_vidblockimg.webp" alt="" className='preimg'/> */}

      <div className='video_page_block1'>
        <div className='landing-container'>
          <div className='video_page_block1_inner'>
            <div className="employer_points_wrapper">
              <div className="employer_heading">
                <p>For the Employer…</p>
              </div>
              <div className="employer_bullet_wrapper">
                <div className="employer_bullet_subwrp">
                  <p className="blue_bullets">
                    Hiring struggles to attract new employees in today’s
                    competitive environment
                  </p>
                  <p className="blue_bullets">
                    Labor shortages, increased turnover, skyrocketing
                    training costs and employee defection
                  </p>
                  <p className="blue_bullets">
                    Going to costly lengths to continue operations, trying
                    to get back to “business as usual”
                  </p>
                </div>
              </div>
              <h3>Addressing an emerging workplace dynamic of worker financial insecurity</h3>
            </div>
          </div>

        </div>

      </div>


    </>
  )
}
